var tempTableData = [];
var selectRowData = [];
import {
	Message
} from 'element-ui'
import {
	handlePageQueryUsers,
	handleViewUserDetails,
	handleSaveUserInfo,
	handleClearUserPassword,
	handleControlUserState,
	handleInitUserPassword,
} from '@manage/api/bm/usermanage';
export default {
	name: 'usermanage',
	components: {},
	data() {
		return {
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			path: process.env.VUE_APP_PROXY_URL + "sys/sysResource/uploadImgs",
			uploadFileParams: {
				type: 'img',
				path: 'user',
				userDefineType: 'user'
			},
			dialogVisible: false,
			editUserForm: {},
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			currSelectRow: null,
			rules: {
				company: [{
					required: true,
					message: '请输入单位全称',
					trigger: 'blur'
				}],
				liablePerson: [{
					required: true,
					message: '请输入联系人',
					trigger: 'blur'
				}],
				concatPhone: [{
					required: true,
					message: '请输入联系方式',
					trigger: 'blur'
				}]
			},
		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryUsers({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				type: '1',
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					tempTableData = res.data;
					this.tableData = tempTableData;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},

		openModifyDialog(id) { //打开修改对话框
			this.dialogVisible = true;
			handleViewUserDetails(id).then(res => {
				if (res.status == 200) {
					this.editUserForm = res.data.user;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		submitInfo() { //保存信息
			handleSaveUserInfo(this.editUserForm).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.dialogVisible = false;
					this.fastQuery();
					this.editUserForm = {};
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		uploadSuccess(res) {
			if (res.status == 200) {
				Message({
					message: res.msg,
					type: 'success',
					duration: 5 * 1000
				});
				this.editUserForm.headerURL = res.data[0].url;
			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				});
			}
		},
		clearUserPassword(id) { //清空用户密码
			handleClearUserPassword(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
		},
		openAddUserDialog() {
			this.editUserForm = {
				headerURL: ''
			};
			this.dialogVisible = true;
		},
		stateControl(id) {
			handleControlUserState(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
		},
		handleSelectionChange(selectData) {
			selectRowData = selectData.map(item => { return item.id });
			this.tableRowClassName({});
		},
		tableRowClassName({ row, rowIndex }) {
			if (rowIndex >= 0 && row && selectRowData.includes(row.id)) {
				return 'selected-row';
			} else {
				return "";
			}
		},
		handleSelectCurrentChange(row) {
			this.currSelectRow = row;
			console.log(">>row>>", row);
		},
		initPassword() {
			if (!this.currSelectRow) {
				Message({
					message: '请选择数据!',
					type: 'error',
					duration: 5 * 1000
				});
				return;
			}
			handleInitUserPassword(this.currSelectRow.id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
		},
		logoutAccountEvent() {
			if (!this.currSelectRow) {
				Message({
					message: '请选择数据!',
					type: 'error',
					duration: 5 * 1000
				});
				return;
			}
			Message({
				message: '此功能还在开发中!',
				type: 'success',
				duration: 5 * 1000
			});
		}
	}
}